.fondo_espejo {
    background: rgba(255, 255, 255, 0.2551953125);
}


.pc {
    display: none;
}

.mobile {
    display:flex;
}

@media (min-width: 800px) {
    .pc {
        display: flex;
    }

    .mobile {
        display: none;
    }
}






.ancho_responsivo {
    width: 100%;
    /* Ancho predeterminado para pantallas menores a 1280px */
}

@media (min-width: 1281px) {

    /* Media query para pantallas más grandes que 1280px */
    .ancho_responsivo {
        width: 1300px;
        /* Establece el ancho a 1300px cuando la pantalla es mayor a 1280px */
    }
}



/* Añade esto en tu archivo CSS o en un bloque <style> */
@media (max-width: 800px) {
    .responsive-text {
        font-size: 9vw;
        /* Esto hará que el texto sea una fracción del ancho de la pantalla */
    }
}