@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 12px; /* Ancho del scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #000000; /* Color de fondo del track */
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #4b4848; /* Color del thumb */
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #e66916; /* Color del thumb al pasar el mouse */
  }

  @media (max-width: 500px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

/* Ajustar body y contenedor principal */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  
  /* Asegúrate de que tu contenedor principal también ocupe el 100% */
  #root, .main-container {
    width: 100%;
    height: 100%;
  }