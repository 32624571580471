.contenedor{
    
    background: linear-gradient(to bottom, rgba(255, 176, 46, 1) 0%,rgba(149, 26, 88, 1) 100%);
    border-radius: 0px;
}
.texto_estirado{
    font-family: "Bebas Neue", sans-serif;
}
@media (min-width: 800px) {
.confirmacion_titulo{
    font-size: 210px;
    color: white;
    font-weight:bold;
}

}
